<template>
    <div class="w-full flex flex-col">
        <div class="leading-normal font-bold" v-text="name"></div>
        <div class="leading-normal" v-for="(value, key) in tag" :key="key">{{key}}: <span class="font-bold text-brand">{{value}}</span></div>
    </div>
</template>

<script>
export default {
  name: 'TagStatisticsPanel',
  props: ['name', 'tag'],
}

</script>

<style scoped>
    @tailwind utilities;
    /* width */
    ::-webkit-scrollbar {
        width: 12px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #dae1e7;
        border-radius: .25rem;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #6a6384;
        border-radius: .25rem;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #b4b0c1;
    }
</style>
