<template>
    <div class="flex flex-col">
        <statistics-filter></statistics-filter>
        <questions-filter :main-url="'/api/statistics?include=slide_type&'" :route="$route">
            <div class="w-full" slot-scope="{link, values}">
                <render-data :base-url="link">
                    <div class="w-full" slot-scope="{data: tags, processing, fetchData}">
                        <statistics-panel v-if="tags && !processing" :tags="tags"></statistics-panel>
                        <spinner v-else></spinner>
                    </div>
                </render-data>
            </div>
        </questions-filter>
    </div>
</template>

<script>
import StatisticsFilter from './filter-panel'
import QuestionsFilter from '../../components/questions/filter'
import RenderData from '../../components/render-data'
import StatisticsPanel from './statistics-panel'
import Spinner from '../../components/spinner'

export default {
  components: { Spinner, StatisticsPanel, RenderData, QuestionsFilter, StatisticsFilter },
  name: 'StatisticsForm',
}

</script>

<style scoped>
    @tailwind utilities;

    .spinner {
        -webkit-animation:spin 4s linear infinite;
        -moz-animation:spin 4s linear infinite;
        animation:spin 4s linear infinite;
    }
    @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
    @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
    @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
</style>
