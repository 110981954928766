<script>
import axios from 'axios'

export default {
  name: 'RenderData',

  props: {
    baseUrl: {
      type: String,
    },
  },
  data () {
    return {
      data: [],
      currentUrl: this.baseUrl,
      processing: false,
    }
  },

  created () {
    this.fetchData()
  },

  watch: {
    baseUrl () {
      this.currentUrl = this.baseUrl
      this.fetchData()
    },
  },

  methods: {
    fetchData () {
      this.processing = true
      axios.get(this.currentUrl).then(({ data }) => {
        this.data = data
        this.processing = false
      })
    },
  },
  render () {
    return this.$scopedSlots.default({
      data: this.data,
      processing: this.processing,
      fetchData: this.fetchData,
    })
  },
}

</script>
