<template>
    <div class="w-full mb-4">
        <div v-if="loaded" class="">
            <div class="flex">
                <a @click.prevent="clear" class="text-brand hover:bg-brand-light mb-2 mr-4">
                    Сбросить все фильтры
                </a>
                <tag-select-panel @tagColumns="updateColumns"></tag-select-panel>
            </div>
          <!--          game types     -->
          <div v-if="tagColumns && tagColumns.game_type_id.required && tagColumns.game_type_id.value" class="pt-1 flex flex-col flex-1">
            <div class="w-full mt-auto">
              <FilterGameTypes ref="gameTypes" @input="addGmaeTypeIdTpFilter">
              </FilterGameTypes>
            </div>
          </div>
            <div class="flex">
                <div v-if="$route.path === '/statistics/authors'"
                     class="flex-auto pt-1 mx-2 flex flex-col">
                    <label class="font-semibold text-brand pb-1">Автор</label>
                    <div class="w-full mt-auto">
                        <select v-model="filter.user_id" class="p-1 border-solid border-brand-light border w-full rounded">
                            <option v-for="tag in tags.user_id" v-text="tag.name" :key="tag.name" :value="tag.id" class="bg-brand rounded text-white hover:bg-brand-dark"></option>
                        </select>
                    </div>
                </div>
                <div v-if="$route.path === '/statistics/authors'"
                     class="flex-auto pt-1 mx-2 flex flex-col">
                    <label class="font-semibold text-brand pb-1">Период</label>
                    <div class="w-full mt-auto">
                        <v-date-picker
                                mode='range'
                                v-model='selectedDate'
                                class="flex-auto py-1.5 px-3 text-grey-darker border-solid border-brand-light border rounded shadow appearance-none"
                                show-caps>
                        </v-date-picker>
                    </div>
                </div>
                <div v-if="gameTypes && Object.keys(subTypes).length && tagColumns && tagColumns.game_type_id.required && tagColumns.game_type_id.value"
                     class="pt-1 mx-2 flex flex-col flex-1">
                  <label class="font-semibold text-brand mt-2 pb-1">Подкатегория</label>
                  <div class="w-full mt-auto">
                    <select v-model="subTypeId" class="p-1 border-solid border-brand-light border w-full rounded">
                      <option value=" " class="bg-brand rounded text-white hover:bg-brand-dark">--Не выбрано--</option>
                      <option v-for="(type, key) in subTypes" :key=key :value="type.id" class="bg-brand rounded text-white hover:bg-brand-dark">{{type.name}}</option>
                    </select>
                  </div>
                </div>
                <div v-if="tagColumns && tagColumns.game_type_id.required && tagColumns.game_type_id.value && Object.keys(gameTopics).length" class="pt-1 mx-2 flex flex-col flex-1">
                  <label class="font-semibold text-brand mt-2 pb-1">Тематика</label>
                  <div class="w-full mt-auto">
                    <select v-model="filter.game_topic_id" class="p-1 border-solid border-brand-light border w-full rounded">
                      <option value=" " class="bg-brand rounded text-white hover:bg-brand-dark">--Не выбрано--</option>
                      <option v-for="(type, key) in gameTopics" :key=key :value="type.id" class="bg-brand rounded text-white hover:bg-brand-dark">{{type.name}}</option>
                    </select>
                  </div>
                </div>
                <div v-if="tagColumns && tagColumns.theme.required && tagColumns.theme.value"
                     class="w-1/3 pt-1 mx-2 flex flex-col">
                    <label class="font-semibold text-brand pb-1">Текст</label>
                    <div class="mt-auto">
                        <search-select
                                v-model="filter.theme"
                                :options="tags.theme"
                                :filter-function="applySearchFilter"
                        >
                        </search-select>
                    </div>
                </div>
                <div v-if="tagColumns && tagColumns.picture.required && tagColumns.picture.value"
                     class="flex-auto pt-1 mx-2 flex flex-col">
                    <label class="font-semibold text-brand pb-1">Картинки</label>
                    <div class="w-full mt-auto">
                        <search-select
                                v-model="filter.picture"
                                :options="tags.picture"
                                :filter-function="applySearchFilter"
                        >
                        </search-select>
                    </div>
                </div>
                <div v-if="tagColumns && tagColumns.blitz.required && tagColumns.blitz.value"
                     class="flex-auto pt-1 mx-2 flex flex-col">
                    <label class="font-semibold text-brand pb-1">Блиц</label>
                    <div class="w-full mt-auto">
                        <search-select
                                v-model="filter.blitz"
                                :options="tags.blitz"
                                :filter-function="applySearchFilter"
                        >
                        </search-select>
                    </div>
                </div>
                <div v-if="tagColumns && tagColumns.company_type.required && tagColumns.company_type.value"
                     class="flex-auto pt-1 mx-2 flex flex-col">
                    <label class="font-semibold text-brand pb-1">Компании</label>
                    <div class="w-full mt-auto">
                        <search-select
                                v-model="filter.company_type"
                                :options="tags.company_type"
                                :filter-function="applySearchFilter"
                        >
                        </search-select>
                    </div>
                </div>
                <div v-if="tagColumns && tagColumns.company_name.required && tagColumns.company_name.value"
                     class="flex-auto pt-1 mx-2 flex flex-col">
                    <label class="font-semibold text-brand pb-1">Имя компании</label>
                    <div class="w-full mt-auto">
                        <input type="text" v-model="filter.company_name"
                               class="w-full p-2 border border-brand-light rounded"
                               placeholder="Текст"/>
                    </div>
                </div>
                <div v-if="tagColumns && tagColumns.pack_number.required && tagColumns.pack_number.value"
                     class="flex-auto pt-2 pr-2 flex flex-col">
                    <label class="font-semibold text-brand pb-1">Номер пакета</label>
                    <div class="w-full mt-auto">
                        <input type="text" v-model="filter.pack_number" class="w-full p-2 border border-brand-light rounded" placeholder="Текст"/>
                    </div>
                </div>
                <div v-if="tagColumns && tagColumns.slide_type_id.required && tagColumns.slide_type_id.value"
                     class="flex-auto pt-1 mx-2 flex flex-col">
                    <label class="font-semibold text-brand pb-1">Формат</label>
                    <div class="w-full mt-auto">
                        <select v-model="filter.slide_type_id" class="p-1 border-solid border-brand-light border w-full rounded">
                            <option v-for="tag in tags.slide_type_id" v-text="tag.name" :key="tag.name" :value="tag.id" class="bg-brand rounded text-white hover:bg-brand-dark"></option>
                        </select>
                    </div>
                </div>

                <div v-if="tagColumns && tagColumns.tour_id.required && tagColumns.tour_id.value"
                     class="flex-auto pt-1 mx-2 flex flex-col">
                    <label class="font-semibold text-brand pb-1">Стандарт вопроса</label>
                    <div class="w-full mt-auto">
                        <select v-model="filter.tour_id" class="p-1 border-solid border-brand-light border w-full rounded">
                            <option value="" class="bg-brand rounded text-white hover:bg-brand-dark">--Не выбрана--</option>
                            <option value="1" class="bg-brand rounded text-white hover:bg-brand-dark">1</option>
                            <option value="2" class="bg-brand rounded text-white hover:bg-brand-dark">2</option>
                            <option value="3" class="bg-brand rounded text-white hover:bg-brand-dark">3</option>
                            <option value="4" class="bg-brand rounded text-white hover:bg-brand-dark">4</option>
                            <option value="5" class="bg-brand rounded text-white hover:bg-brand-dark">5</option>
                            <option value="6" class="bg-brand rounded text-white hover:bg-brand-dark">6</option>
                            <option value="7" class="bg-brand rounded text-white hover:bg-brand-dark">7</option>
                        </select>
                    </div>
                </div>
                <div v-if="tagColumns && tagColumns.question_type.required && tagColumns.question_type.value"
                     class="flex-auto pt-1 mx-2 flex flex-col">
                    <label class="font-semibold text-brand pb-1">Тип вопроса</label>
                    <div class="w-full mt-auto">
                        <select v-model="filter.question_type" class="p-1 border-solid border-brand-light border w-full rounded">
                            <option v-for="tag in tags.question_type" v-text="tag.name" :key="tag.name" :value="tag.id" class="bg-brand rounded text-white hover:bg-brand-dark"></option>
                        </select>
                    </div>
                </div>
                <div v-if="tagColumns && tagColumns.is_special.required && tagColumns.is_special.value"
                     class="flex-auto pt-1 mx-2 flex flex-col">
                    <label class="font-semibold text-brand pb-1">Спец. игра</label>
                    <div class="w-full mt-auto">
                        <input class="select-none" v-model="filter.is_special" type="checkbox"/>
                    </div>
                </div>
                <div v-if="tagColumns && tagColumns.is_adaptive.required && tagColumns.is_adaptive.value"
                     class="flex-auto pt-1 mx-2 flex flex-col">
                  <label class="font-semibold text-brand pb-1">Адаптация</label>
                  <div class="w-full mt-auto">
                    <input class="select-none" v-model="filter.is_adaptive" type="checkbox"/>
                  </div>
                </div>
                <div v-if="tagColumns && tagColumns.complexity.required && tagColumns.complexity.value"
                     class="flex-auto pt-1 mx-2 flex flex-col">
                    <label class="font-semibold text-brand pb-1">Сложность</label>
                    <div class="mt-auto flex flex-row">
                        <div class="flex flex-col mt-auto px-1 justify-center" v-for="(tag, key) in tags.complexity">
                            <p class="text-lg flex w-full">{{tag.name}}</p>
                            <input class="" type="radio" name="complexity" :value="tag.id" v-model="filter.complexity">
                        </div>
                    </div>
                </div>
                <div v-if="tagColumns && tagColumns.city_search_type.required && tagColumns.city_search_type.value"
                     class="flex-auto pt-1 mx-2 flex flex-col">
                    <label class="font-semibold text-brand pb-1">Тип поиска</label>
                    <div class="w-full mt-auto">
                        <select v-model="filter.city_search_type" class="p-1 border-solid border-brand-light border w-full rounded">
                            <option selected value="played" class="bg-brand rounded text-white hover:bg-brand-dark">Сыграные вопросы</option>
                            <option value="not_played" class="bg-brand rounded text-white hover:bg-brand-dark">Не сыграные вопросы</option>
                            <option value="changed" class="bg-brand rounded text-white hover:bg-brand-dark">Заменнные вопросы</option>
                        </select>
                    </div>
                </div>
                <div v-if="tagColumns && tagColumns.city.required && tagColumns.city.value"
                     class="flex-auto pt-1 mx-2 flex flex-col">
                    <label class="font-semibold text-brand pb-1">Город</label>
                    <div class="w-full mt-auto">
                        <select v-model="filter.city" class="p-1 border-solid border-brand-light border w-full rounded">
                            <option v-for="tag in tags.city" v-text="tag.name" :value="tag.id" class="bg-brand rounded text-white hover:bg-brand-dark"></option>
                        </select>
                    </div>
                </div>

                <div v-if="tagColumns && tagColumns.includedRegions.required && tagColumns.includedRegions.value"
                     class="flex-auto pt-1 mx-2 flex flex-col">
                    <label class="font-semibold text-brand pb-1">Регион +</label>
                    <div class="w-full mt-auto">
                        <multi-select v-model="filter.includedRegions" :options="tags.includedRegions"
                                      class="border w-full rounded"></multi-select>
                    </div>
                </div>
                <div v-if="tagColumns && tagColumns.excludedRegions.required && tagColumns.excludedRegions.value"
                     class="flex-auto pt-1 mx-2 flex flex-col">
                    <label class="font-semibold text-brand pb-1">Регион -</label>
                    <div class="w-full mt-auto">
                        <multi-select v-model="filter.excludedRegions" :options="tags.excludedRegions"
                                      class="border w-full rounded"></multi-select>
                    </div>
                </div>
                <div v-if="tagColumns && tagColumns.music_region.required && tagColumns.music_region.value"
                     class="flex-auto pt-1 mx-2 flex flex-col">
                    <label class="font-semibold text-brand pb-1">Локал</label>
                    <div class="w-full mt-auto">
                        <select v-model="filter.music_region"
                                class="p-1 border-solid border-brand-light border w-full rounded">
                            <option v-for="tag in tags.music_region" v-text="tag.name" :value="tag.id"
                                    class="bg-brand rounded text-white hover:bg-brand-dark"></option>
                        </select>
                    </div>
                </div>
                <div v-if="tagColumns && tagColumns.hit.required && tagColumns.hit.value"
                     class="flex-auto pt-1 mx-2 flex flex-col w-full">
                    <label class="font-semibold text-brand pb-1">Хит</label>
                    <div class="w-full flex flex-row mt-auto">
                        <div class="flex px-1 py-2 items-center flex-row">
                            <p class="text-lg mx-1" v-text="'--'"></p>
                            <input class="ml-2" type="radio" name="hit" :value="tags.hit[0].id" v-model="filter.hit">
                        </div>
                        <div class="flex flex-row mx-3 justify-center items-center" v-for="(hit, key) in tags.hit" v-if="key !== 0" :key="key">
                            <label class="font-semibold text-brand pb-1" v-text="hit.name"></label>
                            <input class="ml-2" name="hit" v-model="filter.hit" :value="hit.id" type="radio">
                        </div>
                    </div>
                </div>
                <div v-if="tagColumns && tagColumns.direction.required && tagColumns.direction.value"
                     class="flex-auto pt-1 mx-2 flex flex-col">
                    <label class="font-semibold text-brand pb-1">Стиль</label>
                    <div class="w-full mt-auto">
                        <select v-model="filter.direction" class="p-1 border-solid border-brand-light border w-full rounded">
                            <option v-for="tag in tags.direction" v-text="tag.name" :key="tag.name" :value="tag.id" class="bg-brand rounded text-white hover:bg-brand-dark"></option>
                        </select>
                    </div>
                </div>
                <div v-if="tagColumns && tagColumns.musical_answer_type.required && tagColumns.musical_answer_type.value"
                     class="flex-auto pt-1 mx-2 flex flex-col">
                    <label class="font-semibold text-brand pb-1">Музыка</label>
                    <div class="w-full mt-auto">
                        <select v-model="filter.musical_answer_type" class="p-1 border-solid border-brand-light border w-full rounded">
                            <option v-for="tag in tags.musical_answer_type" v-text="tag.name" :key="tag.name" :value="tag.id" class="bg-brand rounded text-white hover:bg-brand-dark"></option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import debounce from 'lodash/debounce'
import axios from 'axios'
import FilterGameTypes from '../../components/FilterGameTypes.vue'
import SearchSelect from '../../components/SearchSelect.vue'
import TagSelectPanel from '../../components/TagSelectPanel'
import moment from 'moment'
import MultiSelect from '../../components/MultiSelect'

export default {
  components: { MultiSelect, TagSelectPanel, SearchSelect, FilterGameTypes },
  name: 'StatisticsFilter',
  provide () {
    return {
      tagColumns: this.tagColumns,
    }
  },
  data () {
    return {
      selectedDate: {
        start: this.$route.query['start_date'] ? new Date(this.$route.query['start_date']) : null,
        end: this.$route.query['end_date'] ? new Date(this.$route.query['end_date']) : null,
      },
      gameTypes: {},
      typeId: '',
      subTypeId: '',
      tags: [],
      loaded: false,
      tag_local: false,
      tag_hit: false,
      filter: {
        user_id: this.$route.query['user_id'] || '',
        start_date: this.$route.query['start_date'] || '',
        end_date: this.$route.query['end_date'] || '',
        pack_number: this.$route.query['pack_number'] || '',
        slide_type_id: this.$route.query['slide_type_id'] || '',
        tour_id: this.$route.query['tour_id'] || '',
        theme: this.$route.query['theme'] || '',
        question_type: this.$route.query['question_type'] || '',
        complexity: this.$route.query['complexity'] || '',
        includedRegions: [],
        excludedRegions: [],
        hit: this.$route.query['hit'] || '',
        direction: this.$route.query['direction'] || '',
        musical_answer_type: this.$route.query['musical_answer_type'] || '',
        music_region: this.$route.query['music_region'] || '',
        city: this.$route.query['city'] || '',
        city_search_type: this.$route.query['city_search_type'] || 'played',
        main_type: this.$route.query['main_type'] || '',
        sub_type: this.$route.query['sub_type'] || '',
        topic: this.$route.query['topic'] || '',
        is_special: this.$route.query['is_special'] === 'false' ? false : this.$route.query['is_special'] || false,
        is_adaptive: this.$route.query['is_adaptive'] === 'false' ? false : this.$route.query['is_adaptive'] || false,
        picture: this.$route.query['picture'] || '',
        blitz: this.$route.query['blitz'] || '',
        company_type: this.$route.query['company_type'] || '',
        company_name: this.$route.query['company_name'] || '',
        game_type_id: this.$route.query['game_type_id'] || '',
        game_type_name: this.$route.query['game_type_name'] || '',
        game_topic_id: this.$route.query['game_topic_id'] || '',
      },
      tagColumns: {
        show_additional: true,
        city: {
          required: true,
          value: this.$route.path === '/statistics/authors',
        },
        city_search_type: {
          required: true,
          value: this.$route.path === '/statistics/authors',
        },
        game_type_id: {
          required: true,
          value: this.$route.path === '/statistics/authors',
        },
        game_subtype_id: {
          required: false,
          value: false,
        },
        game_topic_id: {
          required: false,
          value: false,
        },
        main_type: {
          required: false,
          value: false,
        },
        sub_type: {
          required: false,
          value: false,
        },
        topic: {
          required: false,
          value: false,
        },
        slide_type_id: {
          required: true,
          value: false,
        },
        pack_number: {
          required: true,
          value: false,
        },
        tour_id: {
          required: true,
          value: true,
        },
        is_special: {
          required: true,
          value: false,
        },
        is_adaptive: {
          required: true,
          value: false,
        },
        theme: {
          required: true,
          value: this.$route.path === '/statistics/questions',
        },
        question_type: {
          required: true,
          value: false,
        },
        complexity: {
          required: true,
          value: this.$route.path === '/statistics/questions',
        },
        includedRegions: {
          required: true,
          value: false,
        },
        excludedRegions: {
          required: true,
          value: false,
        },
        hit: {
          required: true,
          value: false,
        },
        source: {
          required: false,
          value: false,
        },
        direction: {
          required: true,
          value: false,
        },
        musical_answer_type: {
          required: true,
          value: this.$route.path === '/statistics/questions',
        },
        music_region: {
          required: true,
          value: false,
        },
        picture: {
          required: true,
          value: this.$route.path === '/statistics/questions',
        },
        blitz: {
          required: true,
          value: this.$route.path === '/statistics/questions',
        },
        company_type: {
          required: true,
          value: this.$route.path === '/statistics/questions',
        },
        company_name: {
          required: true,
          value: this.$route.path === '/statistics/questions',
        },
      },
    }
  },
  created () {
    axios.get(`/api/tags`).then((data) => {
      this.tags = data.data
      this.filter.includedRegions = this.bindRegion(this.$route.query['includedRegions'])
      this.filter.excludedRegions = this.bindRegion(this.$route.query['excludedRegions'])
      this.loaded = true
    })
    this.getGameTypes()
  },
  watch: {
    'filter.main_type': {
      handler (val) {
        this.filter.sub_type = ''
        this.filter.topic = ''
      },
      deep: true,
    },
    'filter.sub_type': {
      handler (val) {
        this.filter.topic = ''
      },
      deep: true,
    },
    filter: {
      handler (val) {
        if (this.loaded) {
          this.addFilters(val)
        }
      },
      deep: true,
    },
    selectedDate: {
      handler (val) {
        this.filter.start_date = moment(val.start).format('YYYY-MM-DD')
        this.filter.end_date = moment(val.end).format('YYYY-MM-DD')
      },
      deep: true,
    },
    tag_local () {
      this.filter.music_region = ''
      if (this.tag_local === false) {
        let a = this.tags.music_region.filter(music_region => music_region.name === 'Мир')
        console.log(a)
        this.filter.music_region = a[0].id
      }
    },
    tag_hit () {
      this.filter.hit = ''
      if (this.tag_hit === false) {
        this.filter.hit = 2
      }
      if (this.tag_hit === true) {
        this.filter.hit = 1
      }
    },
    typeId (val, oldval) {
      if (oldval !== '') this.filter.game_topic_id = ''
      this.filter.game_type_id = val
      this.filter.game_type_name = this.gameTypes[val] ? this.gameTypes[val].name : ''
    },
    subTypeId (val, oldval) {
      if (oldval !== '') this.filter.game_topic_id = ''
      this.filter.game_type_id = val
      this.filter.game_type_name = this.subTypes[val] ? this.subTypes[val].name : ''
    },
    'filter.theme': function (val, oldval) {
      if (val !== '') {
        this.filter.musical_answer_type = ''
        this.filter.picture = ''
        this.filter.company_type = ''
        this.filter.blitz = ''
      }
    },
    'filter.picture': function (val, oldval) {
      if (val !== '') {
        this.filter.musical_answer_type = ''
        this.filter.theme = ''
        this.filter.company_type = ''
        this.filter.blitz = ''
      }
    },
    'filter.musical_answer_type': function (val, oldval) {
      if (val !== '') {
        this.filter.theme = ''
        this.filter.picture = ''
        this.filter.company_type = ''
        this.filter.blitz = ''
      }
    },
    'filter.blitz': function (val, oldval) {
      if (val !== '') {
        this.filter.theme = ''
        this.filter.picture = ''
        this.filter.company_type = ''
        this.filter.musical_answer_type = ''
      }
    },
    'filter.company_type': function (val, oldval) {
      if (val !== '') {
        this.filter.theme = ''
        this.filter.picture = ''
        this.filter.musical_answer_type = ''
        this.filter.blitz = ''
      }
    },
  },
  computed: {
    musicRegion () {
      return this.tags.music_region.filter(music_region => music_region.name !== 'Мир' && music_region.name !== '-- Не выбрано --')
    },
    subTypes () {
      if (this.typeId && this.gameTypes[this.typeId] && Object.keys(this.gameTypes[this.typeId].subType.data).length > 0) {
        return this.gameTypes[this.typeId].subType ? this.gameTypes[this.typeId].subType.data : []
      }
      return []
    },
    gameTopics () {
      if (this.typeId && this.gameTypes[this.typeId] && Object.keys(this.gameTypes[this.typeId].topics.data).length > 0) {
        return this.gameTypes[this.typeId].topics.data
      }
      if (this.typeId && this.subTypeId && this.gameTypes[this.typeId] && this.gameTypes[this.typeId].subType.data[this.subTypeId] &&
        Object.keys(this.gameTypes[this.typeId].subType.data[this.subTypeId].topics.data).length > 0) {
        return this.gameTypes[this.typeId].subType.data[this.subTypeId].topics.data
      }
      return []
    },

  },
  methods: {
    addGmaeTypeIdTpFilter(gameTypeId){
      this.filter.game_type_id = gameTypeId.query
    },
    applySearchFilter (search, thems) {
      return thems.filter(them => them.name.toLowerCase().startsWith(search.toLowerCase()))
    },
    addFilters: debounce(function (filter) {
      let filterData = {}

      for (let key in filter) {
        if (filter[key] === '') {
          continue
        }
        if (key === 'includedRegions' || key === 'excludedRegions') {
          filterData[key] = filter[key].map(function (obj) {
            return obj.id
          })
          continue
        } filterData[key] = filter[key]
      }
      this.$router.push({ query: filterData })
    }, 500),
    clear () {
      for (let key in this.filter) {
        this.filter[key] = ''
      }
    },
    updateColumns (columns) {
      this.tagColumns = columns
    },
    bindRegion (ids) {
      let result = []
      if (!ids || ids.lenght === 0) {
        return []
      }
      ids = ids instanceof Array ? ids : [ids]
      ids.forEach(id => {
        result.push(this.tags.includedRegions.filter(obj => obj.id == id)[0])
      })
      return result
    },
    getGameTypes () {
      axios.get('/partners/game-types')
        .then((data) => {
          this.gameTypes = data.data.data
          this.getTypeIds()
          // this.addFilters(this.filter);
        })
    },
    getTypeIds () {
      if (this.filter.game_type_id) {
        Object.values(this.gameTypes).forEach((type, key) => {
          if (type.id == this.filter.game_type_id) {
            console.log('gg')
            this.typeId = type.id
            this.subTypeId = ''
          }
          if (Object.keys(type.subType.data).length > 0) {
            Object.values(type.subType.data).forEach((subType) => {
              if (subType.id == this.filter.game_type_id) {
                this.typeId = subType.parent_id
                this.subTypeId = subType.id
              }
            })
          }
        })
      }
    },
  },
}

</script>

<style scoped>
    @tailwind utilities;
</style>
