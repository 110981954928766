<template>
    <div class="rounded border-grey shadow-md text-left w-full px-4 py-2 flex flex-col">
        <div class="text-center font-bold text-xl" v-if="author">{{author}}</div>
        <div class="flex w-full -mx-2">
            <div class="flex flex-1 mx-2">
                <div class="flex-1 mr-2 my-2" v-if="tour==='3'">
                    <tag-statistics-panel class="mx-2 h-88 overflow-x-hidden overflow-y-auto" :name="'Музыка'" :tag="tags.musical_answer_type"></tag-statistics-panel>
                </div>
                <div class="flex-1 mr-2 my-2" v-else-if="tour==='5'">
                    <tag-statistics-panel class="mx-2 h-88 overflow-x-hidden overflow-y-auto" :name="'Картинки'" :tag="tags.picture"></tag-statistics-panel>
                </div>
                <div class="flex-1 mr-2 my-2" v-else-if="tour==='7'">
                    <tag-statistics-panel class="mx-2 h-88 overflow-x-hidden overflow-y-auto" :name="'Блиц'" :tag="tags.blitz"></tag-statistics-panel>
                </div>
                <div class="flex-1 mr-2 my-2" v-else-if="['1','4'].includes(tour) && gameType.toLowerCase().includes('корпорати')">
                    <tag-statistics-panel class="mx-2 h-88 overflow-x-hidden overflow-y-auto" :name="'Компании'" :tag="tags.company_type"></tag-statistics-panel>
                </div>
                <div class="flex-1 mr-2 my-2" v-else>
                    <tag-statistics-panel class="mx-2 h-88 overflow-x-hidden overflow-y-auto" :name="'Текст'" :tag="tags.theme"></tag-statistics-panel>
                </div>
                <div class="flex-1 flex flex-col mx-2 my-2">
                    <tag-statistics-panel v-if="tour==='3'" class="mx-2" :name="'Музыкальный регион'" :tag="tags.music_region"></tag-statistics-panel>
                    <tag-statistics-panel v-else class="mx-2" :name="'Регион'" :tag="tags.includedRegions"></tag-statistics-panel>
                    <tag-statistics-panel class="mx-2 mt-4" :name="'Сложность'" :tag="tags.complexity"></tag-statistics-panel>
                </div>
            </div>
            <div class="flex flex-col flex-1 mx-2">
                <div class="ml-4 p-1 leading-normal font-bold">Всего: <span class="font-bold text-brand">{{tags.total}}</span></div>
                <tour-graph class="flex-auto h-full w-full" v-if="tags.tour_id" :tag="tags.tour_id"></tour-graph>
            </div>
        </div>
    </div>
</template>

<script>
import TourGraph from './tour-graph'
import TagStatisticsPanel from './tag-statistics-panel'
export default {
  name: 'StatisticsPanel',
  components: { TagStatisticsPanel, TourGraph },
  props: ['tags'],
  computed: {
    author () {
      return (this.$route.query.user_id && this.tags.users) ? this.tags.users[this.$route.query.user_id] : false
    },
    tour () {
      return this.$route.query.tour_id ? this.$route.query.tour_id : false
    },
    gameType () {
      return this.$route.query.game_type_name ? this.$route.query.game_type_name : false
    },
  },
}

</script>

<style scoped>
    @tailwind utilities;
</style>
