<template>
    <apexcharts height="345" type="bar" :options="chartOptions" :series="series"></apexcharts>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexcharts: VueApexCharts,
  },
  name: 'TourGraph',
  props: ['tag'],
  data () {
    return {
      chartOptions: {
        chart: {
          id: 'vuechart-questions',
        },
        fill: {
          colors: ['#6a6384', '#b4b0c1'],
        },
        xaxis: {
          categories: Object.keys(this.tag),
          position: 'bottom',
          formatter: function (val) {
            return val
          },
          labels: {
            show: true,
            offsetY: -5,
            formatter: function (val) {
              return val + ' тур'
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'middle',
          offsetY: 0,
          style: {
            fontSize: '12px',
            colors: ['white'],
          },
        },
        title: {
          text: 'Стандарты вопросов',
          floating: true,
          offsetY: 320,
          align: 'center',
          style: {
            color: 'black',
            'font-weight': 700,
          },
        },
        grid: {
          show: false,
        },
      },
      series: [{
        name: 'Количество вопросов',
        data: Object.values(this.tag),
      }],
    }
  },
  created () {
    // this.initChart()
  },
  watch: {
    tag: {
      handler () {
        this.initChart()
      },
      deep: true,
    },
  },
  methods: {
    initChart () {
      this.chartOptions.xaxis.categories = Object.keys(this.tag)
      this.series = [{
        name: 'Количество вопросов',
        data: Object.values(this.tag),
      }]
    },
  },
}

</script>

<style scoped>
    @tailwind utilities;
</style>
