var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[_c('statistics-filter'),_c('questions-filter',{attrs:{"main-url":'/api/statistics?include=slide_type&',"route":_vm.$route},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var link = ref.link;
var values = ref.values;
return _c('div',{staticClass:"w-full"},[_c('render-data',{attrs:{"base-url":link},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.data;
var processing = ref.processing;
var fetchData = ref.fetchData;
return _c('div',{staticClass:"w-full"},[(tags && !processing)?_c('statistics-panel',{attrs:{"tags":tags}}):_c('spinner')],1)}}],null,true)})],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }